import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'akl-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellComponent implements OnInit {
  @Input() width: number;

  @HostBinding('style.width')
  get hostWidth() {
    return this.width + '%';
  }

  constructor() {}

  ngOnInit(): void {}
}
