import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'akl-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor {
  @Output() modelChange = new EventEmitter<any>();
  @Input() formControlName: string;
  @Input() optionValue: any;
  @Input() errorMessages: Record<string, string> = {};
  @Input() disabled = false;

  radioButtonId = '';
  private innerValue: any;
  @ViewChild('input') inputElement: ElementRef;

  onChanged = (_: any) => {};
  onTouched = () => {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(Renderer2) private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.radioButtonId = `${this.formControlName}-${this.optionValue}`;
    this.changeDetectorRef.detectChanges();
  }

  get value(): string {
    return this.innerValue;
  }

  set value(value: string) {
    this.innerValue = value;
    this.onChanged(value);
    this.modelChange.emit(value);
  }

  writeValue(value: string) {
    this.innerValue = value;
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(
      this.inputElement.nativeElement,
      'disabled',
      isDisabled,
    );
  }
}
