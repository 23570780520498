import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AuthState } from '@core/state/auth/auth.state';
import { ApplyTokenInterceptor } from '@core/interceptors/apply-token.interceptor';
import { environment } from '@env';
import { ENVIRONMENT } from '../../environments/environment-token';
import { RefreshTokenInterceptor } from '@core/interceptors/refresh-token.interceptor';
import sessionFactory from './session.factory';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { constants, CONSTANTS } from '@core/constants';
import { ProgramState } from '@core/state/program/program.state';
import {
  DefaultSimpleModalOptionConfig,
  defaultSimpleModalOptions,
  SimpleModalModule,
} from 'ngx-simple-modal';
import { RegisterState } from '@core/state/register/register.state';
import { CoachesResolver } from '@core/resolvers/coaches.resolver';
import { EmployersResolver } from '@core/resolvers/employers.resolver';
import { ProgramsResolver } from '@core/resolvers/programs.resolver';

@NgModule({
  declarations: [],
  providers: [
    CoachesResolver,
    EmployersResolver,
    ProgramsResolver,
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: CONSTANTS,
      useValue: constants,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: sessionFactory,
      deps: [Store, Router, NgxSpinnerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplyTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: DefaultSimpleModalOptionConfig,
      useValue: { ...defaultSimpleModalOptions, ...{ animationDuration: 0 } },
    },
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot([AuthState, RegisterState, ProgramState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth.refreshToken', 'program'],
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsResetPluginModule.forRoot(),
    NgxSpinnerModule,
    SimpleModalModule,
    BrowserAnimationsModule,
  ],
  exports: [NgxSpinnerModule, SimpleModalModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded.');
    }
  }
}
