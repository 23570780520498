import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { trim } from 'lodash';

type InputType = 'text' | 'password' | 'textarea' | 'number';
type InputColor = 'dark' | 'light';

@Component({
  selector: 'akl-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() model?: string;
  @Output() modelChange = new EventEmitter<string>();
  @Input() type: InputType = 'text';
  @Input() color: InputColor = 'dark';
  @Input() label: string;
  @Input() placeholder = '';
  @Input() min?: number;
  @Input() max?: number;
  @Input() errorMessages: Record<string, string> = {};
  @Input() maxLength = 50;
  @Input() mask?: string;
  @Input() prefix = '';
  @Input() readOnly = false;
  @Input() dropSpecialCharacters = false;
  @Input() showMaskTyped = true;
  @Input() editable = false;
  @Input() disablePen = false;
  @Input() iconClass = 'edit';
  @Input() preventNegativeValues = false;

  editMode = true;
  private innerValue: string;
  @ViewChild('input') inputElement: ElementRef;

  onChanged = (_: string) => {
  };
  onTouched = () => {
  };

  constructor(
    @Self() @Optional() public control: NgControl,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(Renderer2) private readonly renderer: Renderer2,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.editMode = !(this.editable && this.value);
    this.changeDetectorRef.detectChanges();
  }

  get value(): string {
    return this.model || this.innerValue;
  }

  set value(value: string) {
    this.innerValue = trim(value);
    this.onChanged(this.innerValue);
    this.modelChange.emit(this.innerValue);
  }

  get invalid(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched, invalid } = this.control;
    return (dirty || touched) && invalid;
  }

  get errors(): string[] {
    if (!this.control || !this.control.errors) {
      return [];
    }

    return Object.keys(this.control.errors).map(
      (key) => this.errorMessages[key],
    );
  }

  onFocusOut() {
    if (!this.editable || !this.value) {
      return;
    }

    this.disabledEditMode();
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.preventNegativeValues && event.key === '-') {
      event.preventDefault();
    }
  }

  enableEditMode() {
    this.editMode = true;
  }

  disabledEditMode() {
    this.editMode = false;
  }

  writeValue(value: string) {
    this.innerValue = value;
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: () => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(
      this.inputElement.nativeElement,
      'disabled',
      isDisabled,
    );
  }
}
