import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

export interface SelectOption<T = any> {
  title: string;
  value: T;
}

type SelectColor = 'dark' | 'light';

@Component({
  selector: 'akl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit, OnDestroy {
  @Input() options: SelectOption[];
  @Input() option: SelectOption;
  @Input() color: SelectColor = 'dark';
  @Output() optionChange = new EventEmitter<SelectOption>();

  @ViewChild('select') select: ElementRef;

  isOpen = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    document.addEventListener('click', this.handleClick, true);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.handleClick, true);
  }

  handleClick = (event: Event) => {
    if (this.isOpen && !this.select.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.changeDetectorRef.detectChanges();
    }
  };

  onSelect(event: Event, option: SelectOption) {
    event.stopPropagation();

    this.optionChange.emit(option);
    this.isOpen = false;
  }

  toggleSelect(event: Event) {
    event.stopPropagation();

    this.isOpen = !this.isOpen;
  }
}
