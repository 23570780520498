<div class="select" #select [ngClass]="{'open': isOpen}" [class]="color">
  <div class="title" (click)="toggleSelect($event)">
    <ng-content></ng-content>
  </div>

  <akl-card
    *ngIf="isOpen"
    class="options"
  >
    <div
      *ngFor="let option of options"
      class="option"
      (click)="onSelect($event, option)"
    >
      {{option.title}}
    </div>
  </akl-card>
</div>
