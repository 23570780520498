import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

export interface TableHeading {
  title: string;
  width: number;
  sort?: string;
}

export enum SortOrder {
  ASC,
  DESC,
}

@Component({
  selector: 'akl-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements OnInit {
  @Input() headings: TableHeading[];
  @Input() items: T[];
  @Input() rowTemplate: TemplateRef<any>;
  @Input() sort: string;
  @Output() sortChange = new EventEmitter<string>();
  @Input() sortOrder: SortOrder;
  @Output() sortOrderChange = new EventEmitter<SortOrder>();
  @Output() rowItemClick = new EventEmitter<T>();
  @Output() onDrop = new EventEmitter<CdkDragDrop<string[]>>();
  @Input() dragNDropEnabled = false;

  constructor() {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<string[]>) {
    this.onDrop.emit(event);
  }

  get isDescOrder() {
    return this.sortOrder === SortOrder.DESC;
  }

  onSortClick(sort: string) {
    if (this.sort === sort) {
      const newSortOrder =
        this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      this.sortOrderChange.emit(newSortOrder);
    } else {
      this.sortChange.emit(sort);
    }
  }

  onRowClick(rowItem: T) {
    this.rowItemClick.emit(rowItem);
  }
}
