import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@core/state/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): boolean {
    const isAuthenticated = this.store.selectSnapshot<boolean>(
      AuthState.isAuthenticated,
    );

    if (isAuthenticated) {
      return true;
    }

    this.router.navigate(['auth/login']);
    return false;
  }
}
