import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'akl-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements OnInit {
  @Input() routerLink: string[] = [];
  @Input() disabled = false;
  @Output() linkClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onLinkClick(event: Event) {
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    this.linkClick.emit();
  }
}
