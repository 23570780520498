<label class="checkbox" [class]="color">
  <input
    type="checkbox"
    [checked]="isChecked"
    (change)="onChanged($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled" />

  <span class="checkmark"></span>

  <span class="content">
    <ng-content></ng-content>
  </span>
</label>
