import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'akl-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() accept: string;
  @Output() handleUpload = new EventEmitter();

  public file: File | null = null;
  onChange: (...args: any[]) => void;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    if (this.onChange) {
      this.onChange(file);
    }
    this.handleUpload.emit(file);
    this.file = file;
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {}

  writeValue() {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {}
}
