import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthState } from './state/auth/auth.state';
import { from, Subject } from 'rxjs';
import { Logout, Refresh } from './state/auth/auth.actions';
import { finalize, switchMap } from 'rxjs/operators';

export default function (
  store: Store,
  router: Router,
  spinner: NgxSpinnerService,
) {
  return () => {
    const refreshToken = store.selectSnapshot<string>(AuthState.refreshToken);

    if (!refreshToken) {
      return Promise.resolve();
    }

    const refreshSession$ = new Subject();

    from(spinner.show())
      .pipe(
        switchMap(() => store.dispatch(new Refresh(refreshToken))),
        finalize(() => spinner.hide()),
      )
      .subscribe(
        () => refreshSession$.complete(),
        () => {
          store.dispatch(new Logout()).subscribe(() => {
            router.navigate(['auth/login']);
            refreshSession$.complete();
          });
        },
      );

    return refreshSession$.toPromise();
  };
}
