<div class="radio-button">
  <input
    #input
    [id]="radioButtonId"
    type="radio"
    [(ngModel)]="value"
    [value]="optionValue"
    [name]="formControlName"
    [disabled]="disabled"
  >
  <label [for]="radioButtonId"><ng-content></ng-content></label>
</div>
