import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { SelectOption } from '@shared/components/select/select.component';
import { Observable, range } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';

@Component({
  selector: 'akl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() range = 3;
  @Input() totalPages: number;
  @Input() currentPage: number;
  @Output() currentPageChange = new EventEmitter<number>();
  @Input() limits?: number[];
  @Input() limit?: number;
  @Output() limitChange = new EventEmitter<number>();

  pages: Observable<number[]>;
  selectedLimitOption: SelectOption;
  limitOptions: SelectOption[];

  constructor() {}

  ngOnInit(): void {
    if (this.limits) {
      this.initLimitOptions();
    }
  }

  ngOnChanges() {
    if (this.limitOptions) {
      this.updateSelectedLimitOption();
    }

    this.pages = this.getPages();
  }

  get firstPage(): number {
    return 1;
  }

  get lastPage(): number {
    return this.totalPages;
  }

  get isPreviousPageValid(): boolean {
    return this.isValidPage(this.currentPage - 1);
  }

  get isNextPageValid(): boolean {
    return this.isValidPage(this.currentPage + 1);
  }

  onPageChange(page: number) {
    this.currentPageChange.emit(page);
  }

  selectPreviousPage() {
    this.currentPageChange.emit(this.currentPage - 1);
  }

  selectNextPage() {
    this.currentPageChange.emit(this.currentPage + 1);
  }

  selectFirstPage() {
    this.currentPageChange.emit(this.firstPage);
  }

  selectLastPage() {
    this.currentPageChange.emit(this.lastPage);
  }

  onLimitOptionChange({ value }: SelectOption) {
    this.limitChange.emit(value);
  }

  isCurrentPage(page: number): boolean {
    return this.currentPage === page;
  }

  private initLimitOptions() {
    this.limitOptions = this.limits.map((limit) => ({
      title: limit.toString(),
      value: limit,
    }));
  }

  private updateSelectedLimitOption() {
    this.selectedLimitOption = this.limitOptions.find((option) => {
      return option.value === this.limit;
    });
  }

  private isValidPage(page: number): boolean {
    return page >= this.firstPage && page <= this.lastPage;
  }

  private getPages() {
    return range(-this.range, this.range * 2 + 1).pipe(
      map((offset) => this.currentPage + offset),
      filter((page) => this.isValidPage(page)),
      toArray(),
    );
  }
}
