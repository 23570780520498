import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'akl-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl'],
})
export class ModalComponent implements OnInit {
  @Input() title: string;
  @Input() width = 300;
  @Output() handleClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
