import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProgramType } from '@core/entities/program';
import { RegisterStateModel, SetQuestionnaireResponse, SetUserPersonalInfo } from './register.actions';
import { ApplyingFor } from '@shared/enums/applying-for.enum';
import { Side } from '@core/entities/exercise';

const getDefaultState = () => ({
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  applyingFor: ApplyingFor.MYSELF,
  email: '',
  address: '',
  secondAddress: '',
  state: '',
  city: '',
  zip: '',
  phone: '',
  type: '' as ProgramType,
  side: '' as Side,
  termsOfUse: false,
  research: false,
  questionnaireResponse: null,
  isPersonalInfoFilled: false,
});

@State<RegisterStateModel>({
  name: 'register',
  defaults: getDefaultState(),
})
@Injectable()
export class RegisterState {
  @Action(SetUserPersonalInfo)
  setUserPersonalInfo(
    ctx: StateContext<RegisterStateModel>,
    action: SetUserPersonalInfo,
  ) {
    ctx.patchState({ ...action.payload, isPersonalInfoFilled: true });
  }

  @Action(SetQuestionnaireResponse)
  setQuestionnaireResponse(
    ctx: StateContext<RegisterStateModel>,
    action: SetQuestionnaireResponse,
  ) {
    ctx.patchState({ questionnaireResponse: action.payload as unknown as Record<string, string> });
  }
}
