import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
  aklLet: T;
}

@Directive({
  selector: '[aklLet]',
})
export class LetDirective<T> {
  private _context: LetContext<T> = { aklLet: null };

  constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
    _viewContainer.createEmbeddedView(_templateRef, this._context);
  }

  @Input()
  set aklLet(value: T) {
    this._context.aklLet = value;
  }
}
