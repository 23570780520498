import { Equipment } from '@core/entities/equipment';

export enum AssessmentType {
  LOW_BACK_BEND = 'lowBackBend',
  LOW_BACK_SIDE_BEND = 'lowBackSideBend',
  SQUAT = 'squat',
  PASSIVE_FLEXION = 'passiveFlexion',
}

export enum BodyPart {
  QUADRICEPS = 'Quadriceps',
  GLUTES = 'Glutes',
  HAMSTRINGS = 'Hamstrings',
  ADDUCTOR = 'Adductor',
  PARASPINALS = 'Paraspinals',
  HIP_FLEXOR = 'Hip Flexor',
  QL = 'QL',
}

export enum ExerciseType {
  TISSUE_MOBILIZATION_TOOL = 'tissueMobilizationTool',
  MOBILITY = 'mobility',
  STRENGTH = 'strength',
  CALIBRATION = 'calibration',
  ASSESSMENT = 'assessment',
}

export enum Side {
  UNDEFINED = 'undefined',
  LEFT = 'left',
  RIGHT = 'right',
  BILATERAL = 'bilateral',
  FORWARD = 'forward',
  BACKWARD = 'backward',
}

export interface ExerciseEquipment {
  equipment: Equipment;
  count: number;
}

export interface ExerciseSide {
  side: Side;
  video: File | string;
  thumbnailImage: File | string;
  timecodes: number[];
}

export interface CalibrationStep {
  exercise: Exercise | string;
}

export interface Exercise {
  _id: string;
  type: ExerciseType;
  name: string;
  description?: string;
  sides?: ExerciseSide[];
  duration: number;
  assessmentType?: AssessmentType;
  isTutorial: boolean;
  hasPreview: boolean;
  reps?: number;
  equipment: ExerciseEquipment[];
  bodyPart?: BodyPart;
  timerOffset?: number;
}
