import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { from } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, switchMap } from 'rxjs/operators';
import { PaginationResponse } from '@shared/interfaces/pagination';
import { EmployersService } from '@core/services/employers.service';
import { Employer } from '@core/entities/employer';

@Injectable()
export class EmployersResolver
  implements Resolve<PaginationResponse<Employer>> {
  constructor(
    private employersService: EmployersService,
    private spinner: NgxSpinnerService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return from(this.spinner.show()).pipe(
      switchMap(() =>
        this.employersService.findAll({
          limit: 1000,
        }),
      ),
      finalize(() => this.spinner.hide()),
    );
  }
}
