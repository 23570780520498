import { SortOrder } from '@shared/components/table/table.component';

export function stringifyValues(
  obj: Record<string, any>,
): Record<string, string> {
  const clonedObj = { ...obj };
  Object.keys(clonedObj).forEach((key) => JSON.stringify(clonedObj[key]));
  return clonedObj;
}

export function getSortQuery(query: string, order: SortOrder): string {
  const isDescOrder = order === SortOrder.DESC;
  return isDescOrder ? `-${query}` : query;
}

export function generateId(): string {
  return Math.random().toString(36).substr(2, 9);
}

export function removePhoneNumberPrefix(phoneNumber: string): string {
  return phoneNumber.replace('+1', '');
}
