import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Environment,
  ENVIRONMENT,
} from '../../../environments/environment-token';
import { BaseUserService } from './base-user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoachesService extends BaseUserService {
  protected baseUrl = `${this.environment.apiUrl}/coaches`;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  getMembers(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/members`);
  }

  getMembersById(id: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/${id}/members/`);
  }
}
