import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { User } from '../../entities/user';
import { AuthService } from '../../services/auth.service';
import { EditProfile, Login, Logout, Refresh } from './auth.actions';

export interface AuthStateModel {
  token: string | null;
  refreshToken: string | null;
  profile: User | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    refreshToken: null,
    profile: null,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string | null {
    return state.refreshToken;
  }

  @Selector()
  static profile(state: AuthStateModel): User | null {
    return state.profile;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  constructor(private authService: AuthService) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    const { username, password } = action;

    return this.authService.login(username, password).pipe(
      tap((response) => {
        ctx.patchState({
          token: response.token,
          refreshToken: response.refreshToken,
          profile: response.user,
        });
      }),
    );
  }

  @Action(EditProfile)
  editProfile(ctx: StateContext<AuthStateModel>, action: EditProfile) {
    const { firstName, lastName } = action;

    return this.authService.editProfile(firstName, lastName).pipe(
      tap((response) => {
        ctx.patchState({
          profile: response,
        })
      })
    )
  }

  @Action(Refresh)
  refresh(ctx: StateContext<AuthStateModel>, action: Refresh) {
    return this.authService.refresh(action.refreshToken).pipe(
      tap((response) => {
        ctx.patchState({
          token: response.token,
          refreshToken: response.refreshToken,
          profile: response.user,
        });
      }),
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, action: Logout) {
    if (action.global) {
      return this.authService.logout().pipe(
        tap(() => {
          ctx.patchState({
            token: null,
            refreshToken: null,
            profile: null,
          });
        }),
      );
    }

    return ctx.patchState({
      token: null,
      refreshToken: null,
      profile: null,
    });
  }
}
