import { AbstractControl } from '@angular/forms';
import isEmail from 'validator/es/lib/isEmail';

export function emailValidator(control: AbstractControl) {
  if (control?.value && !isEmail(control.value)) {
    return {
      email: true,
    };
  }

  return null;
}