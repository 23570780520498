import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { RegisterState } from '@core/state/register/register.state';
import { from, Observable } from 'rxjs';
import { RegisterStateModel, SetQuestionnaireResponse } from '@core/state/register/register.actions';
import { Constants, CONSTANTS } from '@core/constants';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Answer, AuthService, UserQuestionnaire } from '@core/services/auth.service';
import { finalize, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { QuestionnaireResponse, QuestionnaireService } from '@core/services/questionnaire.service';
import { Question, QuestionType } from '@shared/interfaces/questionnaire';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StateReset } from 'ngxs-reset-plugin';

enum YesNoOptions {
  YES = 'Yes',
  NO = 'No',
}

enum BarriersOrChallenges {
  LIMITED_TIME = 'LimitedTime',
  FAMILY_OBLIGATIONS = 'FamilyObligations',
  WORK_OBLIGATIONS = 'WorkObligations',
  OTHER = 'Other',
}

type QuestionnaireColor = 'dark' | 'light';

@UntilDestroy()
@Component({
  selector: 'akl-questionnaire-form',
  templateUrl: './questionnaire-form.component.html',
  styleUrls: ['./questionnaire-form.component.styl'],
})
export class QuestionnaireFormComponent implements OnInit {
  @Input() userQuestionnaire: UserQuestionnaire;
  @Input() questionnaire: QuestionnaireResponse;
  @Input() color: QuestionnaireColor = 'dark';
  @Select(RegisterState) personalInfo$: Observable<RegisterStateModel>;

  form: FormGroup;
  dynamicTextFields: { [fieldName: string]: boolean } = {};
  isFormDisabled = false;
  isFormSubmitted = false;
  apiErrorMessage: string | null = null;

  QuestionType = QuestionType;

  constructor(
    @Inject(CONSTANTS) private constants: Constants,
    private route: ActivatedRoute,
    private authService: AuthService,
    private questionnaireService: QuestionnaireService,
    private spinner: NgxSpinnerService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.isFormDisabled = !!this.userQuestionnaire;
    this.initForm(this.questionnaire);

    if (!this.isFormDisabled) {
      this.form.valueChanges
        .pipe(
          untilDestroyed(this),
        )
        .subscribe((value) => {
          this.store.dispatch(new SetQuestionnaireResponse(value));
        });
    }
  }

  getQuestionList(questionnaire: QuestionnaireResponse): Question[] {
    const questions: Question[] = [];

    questionnaire.questionsBlocks.forEach((questionsBlock) => {
      questionsBlock.sections.forEach((section) => {
        section.questions.forEach((question) => {
          questions.push(question);
        });
      });
    });

    return questions;
  }

  getAnswers(): Answer[] {
    const questions = this.getQuestionList(this.questionnaire);
    return questions
      .filter((question: Question) => question.id)
      .map((question: Question) => ({
        questionId: question.id,
        value: this.form.controls[question.id].value,
        ...(question.dynamicQuestion && {
          dynamicQuestion: {
            questionId: question.dynamicQuestion.id,
            value: this.form.controls[question.dynamicQuestion.id].value,
          },
        }),
      }));
  }

  getAnswersAsMap() {
    if (!this.userQuestionnaire) {
      return {};
    }

    return this.userQuestionnaire.answers.reduce((acc, item) => {
      const copy = { ...acc };
      copy[item.questionId] = item.value;
      if (item.dynamicQuestion) {
        copy[item.dynamicQuestion.questionId] = item.dynamicQuestion.value;
      }

      return copy;
    }, {});
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const { employer } = this.route.snapshot.params;

    const questionnaire: UserQuestionnaire = {
      questionnaire: this.questionnaire.id,
      type: this.questionnaire.type,
      answers: this.getAnswers(),
    };

    from(this.spinner.show())
      .pipe(
        withLatestFrom(this.personalInfo$),
        switchMap(([, personalInfo]) => {
          const {
            firstName,
            lastName,
            type,
            side,
            email,
            applyingFor,
            address,
            secondAddress,
            state,
            city,
            zip,
            phone,
            dateOfBirth,
          } = personalInfo;
          return this.authService.register(employer, {
            firstName,
            lastName,
            dateOfBirth,
            applyingFor,
            email,
            address,
            secondAddress,
            state,
            city,
            zip,
            phone,
            type,
            side,
            questionnaire,
            settings: {},
          });
        }),
        finalize(() => this.spinner.hide()),
        tap(() => this.store.dispatch(new StateReset(RegisterState))),
      )
      .subscribe(
        () => {
          this.isFormSubmitted = true;
          this.apiErrorMessage = null;
        },
        ({ error }) => this.apiErrorMessage = error.message,
      );
  }

  setDynamicValidatorsField(
    primaryFieldName: string,
    secondaryFieldName: string,
  ) {
    this.form.get(primaryFieldName).valueChanges.subscribe((value) => {
      if (value === YesNoOptions.YES || value === BarriersOrChallenges.OTHER) {
        this.form.get(secondaryFieldName).setValidators([Validators.required]);
        this.dynamicTextFields[secondaryFieldName] = true;
      } else {
        this.form.get(secondaryFieldName).clearValidators();
        this.form.get(secondaryFieldName).setValue(null);
        this.dynamicTextFields[secondaryFieldName] = false;
      }

      this.form.get(secondaryFieldName).updateValueAndValidity();
    });
  }

  private initForm(questionnaire: QuestionnaireResponse) {
    const controls = {};
    const fieldsWithDynamicDescription = [];
    const state = this.store.snapshot().register?.questionnaireResponse;

    const questions = this.getQuestionList(questionnaire);

    questions.forEach((question) => {
      controls[question.id] = new FormControl(state ? state[question.id] : null, [Validators.required]);

      if (question.dynamicQuestion) {
        controls[question.dynamicQuestion.id] = new FormControl(state ? state[question.dynamicQuestion.id] : null, []);
        fieldsWithDynamicDescription.push({
          primaryFieldName: question.id,
          secondaryFieldName: question.dynamicQuestion.id,
        });

        if (state && state[question.dynamicQuestion.id]) {
          this.dynamicTextFields[question.dynamicQuestion.id] = true;
        }
      }
    });

    this.form = new FormGroup(controls);

    if (this.userQuestionnaire) {
      const answers = this.getAnswersAsMap();
      questions.forEach((question) => {
        this.form.get(question.id).setValue(answers[question.id]);

        if (question.dynamicQuestion) {
          this.form
            .get(question.dynamicQuestion.id)
            .setValue(answers[question.dynamicQuestion.id]);
          this.dynamicTextFields[question.dynamicQuestion.id] = !!answers[
            question.dynamicQuestion.id
            ];
        }
      });

      this.form.disable();
    }

    fieldsWithDynamicDescription.forEach(
      ({ primaryFieldName, secondaryFieldName }) => {
        this.setDynamicValidatorsField(primaryFieldName, secondaryFieldName);
      },
    );
  }
}
