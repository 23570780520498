import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import * as HttpStatus from 'http-status-codes';
import { Store } from '@ngxs/store';
import { Observable, Subscriber } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthState } from '../state/auth/auth.state';
import {
  Environment,
  ENVIRONMENT,
} from '../../../environments/environment-token';
import { Logout, Refresh } from '../state/auth/auth.actions';

interface CallerRequest {
  subscriber: Subscriber<any>;
  failedRequest: HttpRequest<any>;
}

const EXCLUDED_PATHS = ['auth/refresh', 'auth/login', 'auth/logout'];

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private requests: CallerRequest[] = [];
  private isRefreshInProgress = false;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private store: Store,
    private router: Router,
    private httpClient: HttpClient,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (
      !req.url.includes(this.environment.apiUrl) ||
      this.containsExcludedPath(req.url)
    ) {
      return next.handle(req);
    }

    return new Observable<HttpEvent<any>>((subscriber) => {
      const originalRequestSubscription = next.handle(req).subscribe(
        (response) => subscriber.next(response),
        (err: HttpErrorResponse) => {
          if (err.status === HttpStatus.UNAUTHORIZED) {
            this.handleUnauthorizedError(subscriber, req);
          } else {
            subscriber.error(err);
          }
        },
        () => subscriber.complete(),
      );

      return () => originalRequestSubscription.unsubscribe();
    });
  }

  private handleUnauthorizedError(
    subscriber: Subscriber<any>,
    request: HttpRequest<any>,
  ) {
    this.requests.push({ subscriber, failedRequest: request });

    if (!this.isRefreshInProgress) {
      this.isRefreshInProgress = true;
      const refreshToken = this.store.selectSnapshot<string>(
        AuthState.refreshToken,
      );
      this.store
        .dispatch(new Refresh(refreshToken))
        .pipe(finalize(() => (this.isRefreshInProgress = false)))
        .subscribe(
          () => this.repeatFailedRequests(),
          () => {
            this.store.dispatch(new Logout()).subscribe(() => {
              this.router.navigate(['auth/login']);
            });
          },
        );
    }
  }

  private repeatFailedRequests() {
    const token = this.store.selectSnapshot<string>(AuthState.token);

    this.requests.forEach(({ subscriber, failedRequest }) => {
      const request = failedRequest.clone({
        headers: failedRequest.headers.set('Authorization', `Bearer ${token}`),
      });

      this.repeatRequest(request, subscriber);
    });

    this.requests = [];
  }

  private repeatRequest(
    request: HttpRequest<any>,
    subscriber: Subscriber<any>,
  ) {
    this.httpClient.request(request).subscribe(
      (response) => subscriber.next(response),
      (err: HttpErrorResponse) => {
        if (err.status === HttpStatus.UNAUTHORIZED) {
          this.store.dispatch(new Logout()).subscribe(() => {
            this.router.navigate(['auth/login']);
          });
        }

        subscriber.error(err);
      },
      () => subscriber.complete(),
    );
  }

  private containsExcludedPath(url: string): boolean {
    return EXCLUDED_PATHS.some((path) =>
      url.includes(`${this.environment.apiUrl}/${path}`),
    );
  }
}
