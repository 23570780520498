import { Side } from '@core/entities/exercise';
import { ProgramType } from '@core/entities/program';
import { ApplyingFor } from '@shared/enums/applying-for.enum';
import { Dayjs } from 'dayjs';
import { QuestionnaireResponse } from '@core/services/questionnaire.service';

export interface RegisterStateModel {
  firstName: string;
  lastName: string;
  dateOfBirth: Dayjs;
  applyingFor: ApplyingFor;
  phone: string;
  email: string;
  address: string;
  secondAddress: string;
  state: string;
  city: string;
  zip: string;
  type: ProgramType;
  side: Side;
  termsOfUse: boolean;
  research: boolean;
  questionnaireResponse: Record<string, string>;
  isPersonalInfoFilled: boolean;
}

export class SetUserPersonalInfo {
  static readonly type = '[Register] Set User Personal Info';

  constructor(
    public payload: Omit<RegisterStateModel, 'isPersonalInfoFilled' | 'questionnaireResponse'>,
  ) {
  }
}

export class SetQuestionnaireResponse {
  static readonly type = '[Register] Set Questionnaire Response';

  constructor(
    public payload: Pick<RegisterStateModel, 'questionnaireResponse'>,
  ) {
  }
}
