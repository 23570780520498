<div class="pagination">
  <ul class="pages">
    <li class="control previous">
      <button
        [disabled]="!isPreviousPageValid"
        (click)="selectPreviousPage()"
      >
        <span class="icon-arrow-left"></span>
      </button>
    </li>

    <ng-container *ngIf="(currentPage - range) > 1">
      <li
        class="page"
        (click)="selectFirstPage()"
      >
        {{firstPage}}
      </li>
      <li class="separator">...</li>
    </ng-container>

    <li
      *ngFor="let page of pages | async"
      class="page"
      [class.active]="isCurrentPage(page)"
      (click)="onPageChange(page)"
    >
      {{page}}
    </li>

    <ng-container *ngIf="(currentPage + range) < lastPage">
      <li class="separator">...</li>
      <li
        class="page"
        (click)="selectLastPage()"
      >
        {{lastPage}}
      </li>
    </ng-container>

    <li class="control next">
      <button
        [disabled]="!isNextPageValid"
        (click)="selectNextPage()"
      >
        <span class="icon-arrow-right"></span>
      </button>
    </li>
  </ul>

  <div class="filters">
    <ng-content></ng-content>

    <akl-select
      *ngIf="limits"
      class="limit"
      [option]="selectedLimitOption"
      [options]="limitOptions"
      (optionChange)="onLimitOptionChange($event)"
    >
      Show: {{limit}}
      <span class="icon icon-arrow-down"></span>
    </akl-select>
  </div>
</div>
