import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Conversation } from '@twilio/conversations/lib/data/conversations';
import { Message } from '@twilio/conversations/lib/message';

const ADMIN_IDENTITY = 'ADMIN';

@UntilDestroy()
@Component({
  selector: 'akl-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.styl'],
})
export class MessagesListComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  @Input() conversation: Conversation;

  @ViewChild('messagesEl') messagesEl: ElementRef<HTMLDivElement>;

  messages: Message[] = [];
  prevMessages: Message[];

  private boundConversations: Set<Conversation> = new Set();
  private messageListener;

  constructor() {
  }

  async ngOnInit() {
    this.loadMessagesForConversation(this.conversation);
    this.subscribeToConversation(this.conversation);
    this.conversation.setAllMessagesRead();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { currentValue, previousValue, firstChange } = changes.conversation;
    if (!firstChange && currentValue.sid !== previousValue.sid) {
      this.loadMessagesForConversation(currentValue);
      this.subscribeToConversation(currentValue);
    }
  }

  ngAfterViewChecked() {
    if (this.messagesEl?.nativeElement && this.prevMessages !== this.messages) {
      this.messagesEl.nativeElement.scrollTop = this.messagesEl.nativeElement.scrollHeight;
      this.prevMessages = this.messages;
    }
  }

  ngOnDestroy() {
    this.boundConversations.forEach((conv) =>
      conv.off('messageAdded', this.messageListener),
    );
  }

  private subscribeToConversation(conversation: Conversation) {
    if (!this.boundConversations.has(conversation)) {
      const newConversation = conversation;
      this.messageListener = (m) => this.messageAdded(m, newConversation);
      newConversation.on('messageAdded', this.messageListener);
      this.boundConversations = new Set([
        ...this.boundConversations,
        newConversation,
      ]);
    }
  }

  private async loadMessagesForConversation(conversation: Conversation) {
    const newMessages = await conversation.getMessages();
    this.messages = newMessages.items;
  }

  private messageAdded(message: any, targetConversation: Conversation) {
    if (targetConversation.sid === this.conversation.sid) {
      this.messages = [...this.messages, message];
      this.conversation.setAllMessagesRead();
    }
  }

  isMessageOutgoing(message) {
    return message.author === ADMIN_IDENTITY;
  }
}
