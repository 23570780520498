import { Program, ProgramSessionExercise } from '@core/entities/program';

export class SetProgram {
  static readonly type = '[Create Program] Set Program';

  constructor(public program: Partial<Program>) {}
}

export class AddLevel {
  static readonly type = '[Create Program] Add Level';

  constructor(public sessionIndex: number, public sessionDataIndex: number) {}
}

export class AddExercise {
  static readonly type = '[Create Program] Add Exercise';

  constructor(
    public sessionIndex: number,
    public sessionDataIndex: number,
    public exercise: ProgramSessionExercise,
    public levelIndex?: number,
    public setIndex?: number,
  ) {}
}

export class EditExercise {
  static readonly type = '[Create Program] Edit Exercise';

  constructor(
    public sessionIndex: number,
    public sessionDataIndex: number,
    public exercise: ProgramSessionExercise,
    public exerciseIndex: number,
    public levelIndex?: number,
    public setIndex?: number,
  ) {}
}

export class RemoveExercise {
  static readonly type = '[Create Program] Remove Exercise';

  constructor(
    public sessionIndex: number,
    public sessionDataIndex: number,
    public exerciseIndex: number,
    public levelIndex?: number,
    public setIndex?: number,
  ) {}
}
