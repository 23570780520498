<div class="slider-container" [class]="color">
  <span
    *ngIf="label"
    class="label"
  >
    {{label}}
  </span>

  <div class="slider">
    <ngx-slider
      [(value)]="value"
      [options]="options"
    ></ngx-slider>
  </div>
</div>
