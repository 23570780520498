import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  PaginationParams,
  PaginationResponse,
} from '@shared/interfaces/pagination';
import {
  Environment,
  ENVIRONMENT,
} from '../../../environments/environment-token';
import { Program, ProgramSessionExercise } from '../entities/program';
import { Observable } from 'rxjs';
import { stringifyValues } from '@shared/helpers/common.helper';

export type CreateProgramDto = Omit<Program, '_id'>;

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  private baseUrl = `${this.environment.apiUrl}/programs`;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private httpClient: HttpClient,
  ) {}

  getById(id: string): Observable<Program> {
    return this.httpClient.get<Program>(`${this.baseUrl}/${id}`);
  }

  findAll(
    params: PaginationParams = {},
  ): Observable<PaginationResponse<Program>> {
    const url = this.baseUrl;
    const options = {
      params: stringifyValues(params),
    };

    return this.httpClient.get<PaginationResponse<Program>>(url, options);
  }

  create(program: CreateProgramDto): Observable<Program> {
    return this.httpClient.post<Program>(
      this.baseUrl,
      this.toRawProgram(program),
    );
  }

  delete(id: string): Observable<Program> {
    return this.httpClient.delete<Program>(`${this.baseUrl}/${id}`);
  }

  update(id: string, program: Partial<Program>): Observable<Program> {
    return this.httpClient.patch<Program>(
      `${this.baseUrl}/${id}`,
      this.toRawProgram(program),
    );
  }

  private toRawProgram(program: Partial<Program>) {
    return {
      ...program,
      sessions: program.sessions.map((session) => ({
        ...session,
        data: session.data.map((sessionData) => ({
          ...sessionData,
          exercises:
            sessionData.exercises &&
            this.mapToRawExercises(sessionData.exercises),
          levels:
            sessionData.levels &&
            sessionData.levels.map((level) => this.mapToRawExercises(level)),
          sets:
            sessionData.sets &&
            sessionData.sets.map((set) => this.mapToRawExercises(set)),
        })),
      })),
    };
  }

  private mapToRawExercises(exercises: ProgramSessionExercise[]) {
    return exercises.map((item) => ({
      ...item,
      exercise: item.exercise._id,
    }));
  }
}
