import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Environment,
  ENVIRONMENT,
} from '../../../environments/environment-token';
import { ProgramType } from '@core/entities/program';
import { QuestionsBlock } from '@shared/interfaces/questionnaire';

export enum QuestionnaireType {
  REGISTER = 'register',
  PROGRAM = 'program',
  DIFFICULTY = 'difficulty',
  PAIN = 'pain',
}

export interface QuestionnaireResponse {
  id: string;
  title: string;
  questionsBlocks: QuestionsBlock[];
  type: QuestionnaireType;
  programType: ProgramType;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private httpClient: HttpClient,
  ) {}

  getById(id: string) {
    const url = `${this.environment.apiUrl}/questionnaires/${id}`;

    return this.httpClient.get<QuestionnaireResponse>(url);
  }

  find(programType: ProgramType) {
    const url = `${this.environment.apiUrl}/questionnaires?programType=${programType}`;

    return this.httpClient.get<QuestionnaireResponse>(url);
  }
}
