export enum QuestionType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  RADIO = 'radio',
  SLIDER = 'slider',
  SELECT = 'select',
}

export interface Option {
  id: string;
  label: string;
  value: string;
}

export interface Question {
  id: string;
  title: string;
  type: QuestionType;
  options?: Option[];
  dynamicQuestion?: Question;
}

export interface QuestionsSection {
  title?: string;
  questions: Array<Question>;
}

export interface QuestionsBlock {
  title: string;
  subtitle?: string;
  sections: Array<QuestionsSection>;
}
