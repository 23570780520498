<akl-card class="modal">
  <div class="header">
    <h4 class="title">{{title}}</h4>

    <button
      class="close"
      (click)="handleClose.emit()"
    >
      <span class="icon icon-close"></span>
    </button>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</akl-card>

<div class="overlay"></div>
