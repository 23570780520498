import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'akl-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.styl'],
})
export class BackComponent implements OnInit {
  @Input() route: string;

  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {}

  onClick() {
    if (this.route) {
      this.router.navigate([this.route]);
    } else {
      this.location.back();
    }
  }
}
