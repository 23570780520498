import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddExercise,
  AddLevel,
  EditExercise,
  RemoveExercise,
  SetProgram,
} from './program.actions';
import { Program, SessionType } from '@core/entities/program';
import { ExerciseType } from '@core/entities/exercise';
import produce from 'immer';
import { isNumber } from 'lodash';
import { generateId } from '@shared/helpers/common.helper';

export type ProgramStateModel = Program;

const getDefaultState = () => ({
  name: '',
  type: null,
  sessions: [
    {
      id: generateId(),
      type: SessionType.MobilityPlusStrength,
      data: [
        {
          type: ExerciseType.TISSUE_MOBILIZATION_TOOL,
          exercises: [],
        },
        {
          type: ExerciseType.MOBILITY,
          sets: [[], []],
        },
        {
          type: ExerciseType.STRENGTH,
          levels: [],
        },
        {
          type: ExerciseType.ASSESSMENT,
          exercises: [],
        },
      ],
    },
    {
      id: generateId(),
      type: SessionType.RecoveryPlusMobility,
      data: [
        {
          type: ExerciseType.TISSUE_MOBILIZATION_TOOL,
          exercises: [],
        },
        {
          type: ExerciseType.MOBILITY,
          exercises: [],
        },
      ],
    },
  ],
});

@State<ProgramStateModel>({
  name: 'program',
  defaults: getDefaultState(),
})
@Injectable()
export class ProgramState {
  @Action(SetProgram)
  setProgram(ctx: StateContext<ProgramStateModel>, action: SetProgram) {
    ctx.patchState(action.program);
  }

  @Action(AddLevel)
  addLevel(
    ctx: StateContext<ProgramStateModel>,
    { sessionIndex, sessionDataIndex }: AddLevel,
  ) {
    ctx.patchState({
      sessions: produce(ctx.getState().sessions, (draft) => {
        draft[sessionIndex].data[sessionDataIndex].levels.push([]);
      }),
    });
  }

  @Action(AddExercise)
  addExercise(
    ctx: StateContext<ProgramStateModel>,
    {
      sessionIndex,
      sessionDataIndex,
      exercise: exerciseData,
      levelIndex,
      setIndex,
    }: AddExercise,
  ) {
    ctx.patchState({
      sessions: produce(ctx.getState().sessions, (draft) => {
        const exercise = {
          ...exerciseData,
          id: generateId(),
        };

        if (isNumber(levelIndex)) {
          draft[sessionIndex].data[sessionDataIndex].levels[levelIndex].push(
            exercise,
          );
        } else if (isNumber(setIndex)) {
          draft[sessionIndex].data[sessionDataIndex].sets[setIndex].push(
            exercise,
          );
        } else {
          draft[sessionIndex].data[sessionDataIndex].exercises.push(exercise);
        }
      }),
    });
  }

  @Action(EditExercise)
  editExercise(
    ctx: StateContext<ProgramStateModel>,
    {
      sessionIndex,
      sessionDataIndex,
      exercise,
      exerciseIndex,
      levelIndex,
      setIndex,
    }: EditExercise,
  ) {
    ctx.patchState({
      sessions: produce(ctx.getState().sessions, (draft) => {
        if (isNumber(levelIndex)) {
          draft[sessionIndex].data[sessionDataIndex].levels[levelIndex][
            exerciseIndex
          ] = exercise;
        } else if (isNumber(setIndex)) {
          draft[sessionIndex].data[sessionDataIndex].sets[setIndex][
            exerciseIndex
          ] = exercise;
        } else {
          draft[sessionIndex].data[sessionDataIndex].exercises[
            exerciseIndex
          ] = exercise;
        }
      }),
    });
  }

  @Action(RemoveExercise)
  removeExercise(
    ctx: StateContext<ProgramStateModel>,
    {
      sessionIndex,
      sessionDataIndex,
      exerciseIndex,
      levelIndex,
      setIndex,
    }: RemoveExercise,
  ) {
    ctx.patchState({
      sessions: produce(ctx.getState().sessions, (draft) => {
        if (isNumber(levelIndex)) {
          draft[sessionIndex].data[sessionDataIndex].levels[levelIndex].splice(
            exerciseIndex,
            1,
          );
        } else if (isNumber(setIndex)) {
          draft[sessionIndex].data[sessionDataIndex].sets[setIndex].splice(
            exerciseIndex,
            1,
          );
        } else {
          draft[sessionIndex].data[sessionDataIndex].exercises.splice(
            exerciseIndex,
            1,
          );
        }
      }),
    });
  }
}
