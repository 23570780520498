import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider/options';

export type SliderOptions = Options;
type SliderColor = 'dark' | 'light';

@Component({
  selector: 'akl-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SliderComponent),
    },
  ],
})
export class SliderComponent implements OnInit, ControlValueAccessor {
  @Input() label?: string;
  @Input() color: SliderColor = 'dark';
  @Input() options: SliderOptions = {
    floor: 0,
    ceil: 100,
  };
  @Input() model?: number;
  @Output() modelChange = new EventEmitter<number>();

  innerValue: number;
  onChange = (_: number) => {};

  constructor() {}

  ngOnInit(): void {}

  get value(): number {
    return this.model || this.innerValue || 0;
  }

  set value(value: number) {
    this.innerValue = value;
    this.onChange(value);
    this.modelChange.emit(value);
  }

  writeValue(value: number) {
    this.innerValue = value || 0;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {}

  setDisabledState(isDisabled: boolean) {
    this.options = Object.assign({}, this.options, { disabled: isDisabled });
  }
}
