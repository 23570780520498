import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aklFilter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], text: string, field: string): any[] {
    if (!items) {
      return [];
    }

    if (!text) {
      return items;
    }

    const loweredText = text.toLocaleLowerCase();

    return items.filter((item) =>
      item[field].toLocaleLowerCase().includes(loweredText),
    );
  }
}
