<label
  class="input-container"
  [class]="color"
  [ngClass]="{'textarea': type === 'textarea', 'invalid': invalid}"
>
  <span
    *ngIf="editMode && label"
    class="label"
  >
    {{label}}
  </span>

  <div
    *ngIf="editMode; else nonEditMode"
    [ngSwitch]="type"
    class="edit-container"
  >
    <textarea
      #input
      *ngSwitchCase="'textarea'"
      class="input text-area"
      [class.invalid]="invalid"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [maxLength]="maxLength"
      (focusout)="onFocusOut()"
    ></textarea>

    <input
      #input
      *ngSwitchDefault
      class="input"
      [type]="type"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [min]="min"
      [max]="max"
      [maxLength]="maxLength"
      [mask]="mask"
      [prefix]="prefix"
      [readOnly]="readOnly"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [showMaskTyped]="showMaskTyped"
      (focusout)="onFocusOut()"
      (keydown)="onKeyDown($event)"
    >
  </div>

  <ng-template #nonEditMode>
    <div
      class="non-edit-container"
      aklStopPropagation
    >
      <span class="non-edit-value">{{value}}</span>

      <button
        *ngIf="!disablePen"
        class="edit"
        (click)="enableEditMode()"
      >
        <span [ngClass]="'icon-'+iconClass" class="icon"></span>
      </button>
    </div>
  </ng-template>
</label>
<div
  *ngIf="editMode"
  class="error"
  [style.visibility]="invalid ? 'visible' : 'hidden'"
>
  {{errors[0]}}
</div>
