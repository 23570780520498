import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export enum CardType {
  OUTLINED = 'outlined',
  DEFAULT = 'default',
}

@Component({
  selector: 'akl-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit {
  @Input() type: CardType = CardType.DEFAULT;

  constructor() {}

  ngOnInit(): void {}
}
