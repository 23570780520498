import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'akl-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class SearchComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;

  public control: FormControl = new FormControl();
  private onChange: (value: string) => void;
  private onTouch: () => void;
  
  constructor() {}

  ngOnInit(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
    });
  }

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

}
