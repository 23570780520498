import { Pipe, PipeTransform } from '@angular/core';
import { SessionType } from '@core/entities/program';

const typeTitleMapping = {
  [SessionType.MobilityPlusStrength]: 'Strength',
  [SessionType.RecoveryPlusMobility]: 'Mobility',
};

@Pipe({
  name: 'aklProgramSessionTitle',
})
export class SessionTitlePipe implements PipeTransform {
  transform(value: SessionType, ...args: unknown[]): unknown {
    return typeTitleMapping[value];
  }
}
