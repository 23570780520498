import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type CheckboxColor = 'dark' | 'light';

@Component({
  selector: 'akl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() color: CheckboxColor = 'dark';

  isChecked = false;
  onChange = (_) => ({});
  onBlur = (_) => ({});

  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged($event) {
    this.isChecked = $event?.target?.checked;
    this.onChange(this.isChecked);
  }
}
