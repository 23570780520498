import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { State, User } from '@core/entities/user';
import { Program } from '@core/entities/program';
import { Employer } from '@core/entities/employer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { removePhoneNumberPrefix } from '@shared/helpers/common.helper';
import { Side } from '@core/entities/exercise';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { emailValidator } from '@shared/validators/email.validator';

@Component({
  selector: 'akl-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent implements OnInit {
  @Input() user: User;
  @Input() title: string;
  @Input() dateTitle: string;
  @Input() programs: Program[];
  @Input() employers: Employer[];
  @Input() coaches: User[];
  @Input() rejectTitle: string;
  @Input() submitTitle: string;
  @Input() readOnly = false;
  @Output() handleReject = new EventEmitter();
  @Output() handleSubmit = new EventEmitter();

  form: FormGroup;

  stateOptions = Object.values(State).map(state => ({
    title: state,
    value: state,
  }));

  sideOptions = [
    {
      title: 'Undefined',
      value: Side.UNDEFINED,
    },
    {
      title: 'Left',
      value: Side.LEFT,
    },
    {
      title: 'Right',
      value: Side.RIGHT,
    },
    {
      title: 'Bilateral',
      value: Side.BILATERAL,
    },
  ];

  constructor(private simpleModalService: SimpleModalService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSubmitClick() {
    if (this.form.invalid) {
      return;
    }

    this.handleSubmit.emit({
      ...this.form.value,
      programType: this.form.value.memberProgram &&
        this.programs.find((item) => item._id === this.form.value.memberProgram).type,
      phoneNumber: `+1${this.form.value.phoneNumber}`,
    });
  }

  onRejectClick() {
    this.simpleModalService
      .addModal(ConfirmationModalComponent, {
        title: this.rejectTitle,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.handleReject.emit(this.user);
        }
      });
  }

  private initForm() {
    this.form = new FormGroup({
      firstName: new FormControl(
        this.user?.firstName || '',
        Validators.required,
      ),
      lastName: new FormControl(this.user?.lastName || '', Validators.required),
      phoneNumber: new FormControl(
        this.user?.phoneNumber &&
        removePhoneNumberPrefix(this.user.phoneNumber),
        [Validators.required],
      ),
      email: new FormControl(this.user?.email || '', [
        Validators.required,
        emailValidator,
      ]),
      address: new FormControl(this.user?.address || '', Validators.required),
      secondAddress: new FormControl(this.user?.secondAddress || ''),
      state: new FormControl(this.user?.state || '', Validators.required),
      city: new FormControl(this.user?.city || '', Validators.required),
      zip: new FormControl(this.user?.zip || '', Validators.required),
      coach: new FormControl(this.user?.coach?._id, Validators.required),
      involvedSide: new FormControl(
        this.user?.involvedSide,
        Validators.required,
      ),
      memberProgram: new FormControl(
        this.user?.memberProgram?._id,
        Validators.required,
      ),
      employer: new FormControl(this.user?.employer?._id, Validators.required),
    });

    if (this.readOnly) {
      this.form.disable();
    }
  }
}
