<label class="search">
  <span class="icon icon-search"></span>

  <input
    type="text"
    class="input"
    [formControl]="control"
    [placeholder]="placeholder"
  >
</label>
