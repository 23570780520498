import { Employer } from '@core/entities/employer';
import { Program, ProgramType, SessionType } from '@core/entities/program';
import { UserQuestionnaire } from '@core/services/auth.service';
import { AssessmentType, ExerciseType, Side } from '@core/entities/exercise';

export enum UserRole {
  ADMIN = 'admin',
  APPLICANT = 'applicant',
  MEMBER = 'member',
  COACH = 'coach',
}

export enum DayOfWeek {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum UserStatus {
  NEW = 'new',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
}

export enum ExerciseFamilyType {
  LOWER_LIMBS_EXERCISES = 'LowerLimbsExercises',
  LOWER_BACK_EXERCISES = 'LowBackExercises',
  UNDEFINED = 'Undefined',
}

export enum BodyPlane {
  UNDEFINED = 'Undefined',
  SAGITTAL_PLANE = 'SagittalPlane',
  CORONAL_PLANE = 'CoronalPlane',
  TRANSVERSE_PLANE = 'TransversePlane',
}

export enum UpperBoundSide {
  NEUTRAL = 'neutral',
  RIGHT = 'right',
  LEFT = 'left',
  FRONT = 'front',
  BACK = 'back',
}

export enum DegreeChange {
  NONE = 'none',
  UP = 'up',
  DOWN = 'down',
}

export interface UpperBound {
  side: UpperBoundSide;
  value: number;
}

export interface RangeOfMotionChanges {
  exerciseId: string;
  exerciseFamilyType: ExerciseFamilyType;
  changes: DegreeChange;
  value: number;
}

export interface JointPosition {
  lowerBound?: number;
  upperBound?: number;
  range?: number;
  count?: number;
  bonusCount?: number;
}

export interface BodyPlanePositions {
  bodyPlane: BodyPlane;
  upperBounds?: UpperBound[];
  range?: number;
}

export interface RangeOfMotionProgress {
  exerciseFamilyType: ExerciseFamilyType;
  jointPosition?: JointPosition;
  bodyPlanePositions?: BodyPlanePositions[];
}

export interface ExerciseProgress {
  exerciseId: string;
  type: ExerciseType;
  reps?: number;
  duration?: number;
  rangeOfMotionProgress: RangeOfMotionProgress;
}

export interface MeasurementResult {
  side: Side;
  lowerBound?: number;
  upperBound?: number;
  range?: number;
  count?: number;
}

export interface AssessmentProgress {
  duration?: number;
  assessmentType: AssessmentType;
  measurementResults: MeasurementResult[];
}

export interface SessionProgress {
  sessionId: string;
  type: SessionType;
  level: number;
  set?: number;
  date: Date;
  side?: Side;
  isRepeat?: boolean;
  isRestDay?: boolean;
  hasAssessment?: boolean;
  exerciseProgress: ExerciseProgress[];
  questionnaireResults?: UserQuestionnaire[];
  readArticle?: string;
  difficultyChanges: DegreeChange;
  painChanges: DegreeChange;
  rangeOfMotionChanges: RangeOfMotionChanges[];
  assessmentProgress: AssessmentProgress[];
}

export interface ProgramProgress {
  sessionsProgress: SessionProgress[];
}

export class RemindersSettings {
  hour: number;
  minute: number;
  timezone: string;
  daysOfWeek: DayOfWeek[];
}

export interface Note {
  date: string;
  value: string;
}

export class Settings {
  reminders?: RemindersSettings;
}

export enum State {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  image?: string;
  role: UserRole;
  status: UserStatus;
  employer?: Employer;
  coach?: User;
  involvedSide?: Side;
  memberProgram?: Program;
  programProgress: ProgramProgress;
  dateOfBirth?: string;
  phoneNumber?: string;
  address?: string;
  secondAddress?: string;
  state?: string;
  city?: string;
  zip?: string;
  registrationDate: string;
  userQuestionnaires?: UserQuestionnaire[];
  programType?: ProgramType;
  side?: Side;
  notes: Note[];
  settings: Settings;
  lastSessionDate?: Date;
  rejectionReason?: string;
}
