<img
  *ngIf="src; else placeholder"
  class="avatar"
  [src]="src"
  [alt]="initials"
>

<ng-template #placeholder>
  <div class="avatar">
    {{initials}}
  </div>
</ng-template>
