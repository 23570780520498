import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PaginationParams,
  PaginationResponse,
} from '@shared/interfaces/pagination';
import { User, UserStatus } from '@core/entities/user';
import { stringifyValues } from '@shared/helpers/common.helper';

export interface FindAllUsersDto extends PaginationParams {
  fullName?: string;
  status?: UserStatus;
  employer?: string;
}

@Injectable()
export abstract class BaseUserService {
  protected abstract baseUrl: string;

  constructor(protected httpClient: HttpClient) {}

  create(user: Partial<User>): Observable<User> {
    return this.httpClient.post<User>(this.baseUrl, user);
  }

  update(id: string, user: Partial<User>): Observable<User> {
    return this.httpClient.patch<User>(`${this.baseUrl}/${id}`, user);
  }

  getById(id: string): Observable<User> {
    return this.httpClient.get<User>(`${this.baseUrl}/${id}`);
  }

  findAll(params: FindAllUsersDto = {}): Observable<PaginationResponse<User>> {
    const options = {
      params: stringifyValues(params),
    };

    return this.httpClient.get<PaginationResponse<User>>(this.baseUrl, options);
  }

  delete(id: string): Observable<User> {
    return this.httpClient.delete<User>(`${this.baseUrl}/${id}`);
  }
}
