import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'akl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() type = 'button';
  @Input() disabled = false;
  @Output() handleClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
