<div class="table">
  <div class="head">
    <div class="row">
      <akl-table-cell
        *ngFor="let heading of headings"
        [width]="heading.width"
      >
        <span
          *ngIf="!heading.sort; else sortable"
          [innerHTML]="heading.title"
        ></span>

        <ng-template #sortable>
          <button
            class="sort-button"
            [class.active]="heading.sort === sort"
            (click)="onSortClick(heading.sort)"
          >
            {{heading.title}}

            <span
              *ngIf="heading.sort === sort && isDescOrder; else ascOrder"
              class="icon icon-arrow-up"
            ></span>

            <ng-template #ascOrder>
              <span class="icon icon-arrow-down"></span>
            </ng-template>
          </button>
        </ng-template>
      </akl-table-cell>
    </div>
  </div>


  <ng-container *ngIf="dragNDropEnabled; else tableDefault">
    <div class="body" cdkDropList (cdkDropListDropped)="drop($event)">
      <div
        cdkDrag
        *ngFor="let item of items"
        class="row pointer"
        (click)="onRowClick(item)"
      >
        <ng-container
          *ngTemplateOutlet="rowTemplate; context: { item: item }"
        ></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #tableDefault>
  <div class="body">
    <div
      *ngFor="let item of items"
      class="row"
      (click)="onRowClick(item)"
    >
      <ng-container
        *ngTemplateOutlet="rowTemplate; context: { item: item }"
      ></ng-container>
    </div>
  </div>
</ng-template>

