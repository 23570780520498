<form
  class="form"
  *ngIf="questionnaire && !isFormSubmitted; else formSubmitted"
  [class]="color"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <ng-container *ngFor="let block of questionnaire.questionsBlocks; let i = index">
    <ng-container>
      <div class="title" *ngIf="i === 0 && !userQuestionnaire">
        {{ block.title }}
        <div class="subtitle red" *ngIf="block.subtitle">{{ block.subtitle }}</div>
      </div>

      <div class="title" *ngIf="i === 0 && userQuestionnaire">
        Questionnaire Response
      </div>

      <div class="title" *ngIf="i !== 0">
        {{ block.title }}
        <div class="subtitle" *ngIf="block.subtitle">{{ block.subtitle }}</div>
      </div>
    </ng-container>

    <ng-container *ngFor="let section of block.sections">
      <div class="section-question" *ngIf="section.title">{{ section.title }}</div>

      <ng-container *ngFor="let primaryQuestion of section.questions">
        <ng-container *ngTemplateOutlet="questionTemplate; context:{question: primaryQuestion}"></ng-container>
        <ng-container *ngIf="primaryQuestion.dynamicQuestion && dynamicTextFields[primaryQuestion.dynamicQuestion.id]">
          <ng-container
            *ngTemplateOutlet="questionTemplate; context:{question: primaryQuestion.dynamicQuestion}"></ng-container>
        </ng-container>

        <ng-template #questionTemplate let-question="question">
          <ng-container *ngIf="!userQuestionnaire; else readOnly" [ngSwitch]="question.type">
            <akl-input
              *ngSwitchCase="QuestionType.TEXTAREA"
              [label]="question.title"
              [formControlName]="question.id"
              type="textarea"
              maxLength="350"
              [color]="color"
            ></akl-input>

            <akl-radio-button-group
              *ngSwitchCase="QuestionType.RADIO"
              [label]="question.title"
              [color]="color"
            >
              <akl-radio-button
                *ngFor="let option of question.options"
                [optionValue]="option.value"
                [formControlName]="question.id"
              >{{ option.label }}</akl-radio-button>
            </akl-radio-button-group>

            <akl-slider
              *ngSwitchCase="QuestionType.SLIDER"
              [label]="question.title"
              [formControlName]="question.id"
              [color]="color"
            ></akl-slider>


            <div class="select-container" *ngSwitchCase="QuestionType.SELECT">
              <span class="label">{{question.title}}</span>
              <ng-select [formControlName]="question.id">
                <ng-option
                  *ngFor="let item of question.selectItems"
                  [value]="item.value"
                >
                  {{item.title}}
                </ng-option>
              </ng-select>
            </div>

            <akl-input
              *ngSwitchCase="QuestionType.NUMBER"
              type="number"
              [label]="question.title"
              [formControlName]="question.id"
              [preventNegativeValues]="true"
              [color]="color"
            ></akl-input>

            <akl-input
              *ngSwitchCase="QuestionType.TEXT"
              [label]="question.title"
              [formControlName]="question.id"
              [color]="color"
              maxLength="250"
            ></akl-input>

          </ng-container>
          <ng-template #readOnly>
            <akl-card type="outlined" class="card">
              <p class="subtitle">{{question.title}}</p>
              <p>{{form.controls[question.id].value}}</p>
            </akl-card>
          </ng-template>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>


  <div
    class="subtitle red"
    *ngIf="!isFormDisabled && form.invalid"
  >Please fill in all the fields
  </div>
  <div
    *ngIf="apiErrorMessage"
    class="api-error"
  >
    {{apiErrorMessage}}
  </div>
  <akl-button
    *ngIf="!isFormDisabled"
    class="submit"
    type="submit"
    [disabled]="form.invalid"
  >
    Register
  </akl-button>
</form>

<ng-template #formSubmitted>
  <div class="title">
    Thanks for your application! Your information is being reviewed, you'll be contacted soon
  </div>
</ng-template>
