<div class="user-details">
  <h3 class="feature-title">
    {{title}}

    <span *ngIf="user?.registrationDate" class="registration-date">
      {{dateTitle}}: {{user.registrationDate | date: 'MM-dd-yyyy'}}
    </span>
  </h3>

  <form
    class="form"
    [formGroup]="form"
  >
    <akl-input
      label="First Name"
      formControlName="firstName"
    ></akl-input>

    <akl-input
      label="Last Name"
      formControlName="lastName"
    ></akl-input>

    <akl-input
      label="Phone Number"
      prefix="+1 "
      mask="(000) 000-0000"
      [dropSpecialCharacters]="true"
      formControlName="phoneNumber"
    ></akl-input>

    <akl-input
      label="Email Address"
      formControlName="email"
    ></akl-input>

    <akl-input
      label="Address"
      type="textarea"
      [maxLength]="250"
      formControlName="address"
    ></akl-input>

    <akl-input
      label="Address 2"
      type="textarea"
      [maxLength]="250"
      formControlName="secondAddress"
    ></akl-input>

    <akl-select-wrapper label="State">
      <ng-select
        formControlName="state"
      >
        <ng-option
          *ngFor="let state of stateOptions"
          [value]="state.value"
        >
          {{state.title}}
        </ng-option>
      </ng-select>
    </akl-select-wrapper>

    <akl-input
      label="City"
      formControlName="city"
    ></akl-input>

    <akl-input
      label="Zip"
      formControlName="zip"
    ></akl-input>

    <h3 class="feature-title">
      Client & Program Detail
    </h3>

    <akl-select-wrapper label="Therapist">
      <ng-select
        formControlName="coach"
      >
        <ng-option
          *ngFor="let coach of coaches"
          [value]="coach._id"
        >
          {{coach.fullName}}
        </ng-option>
      </ng-select>
    </akl-select-wrapper>

    <akl-select-wrapper label="Involved Side">
      <ng-select
        formControlName="involvedSide"
      >
        <ng-option
          *ngFor="let side of sideOptions"
          [value]="side.value"
        >
          {{side.title}}
        </ng-option>
      </ng-select>
    </akl-select-wrapper>

    <akl-select-wrapper label="Program">
      <ng-select
        formControlName="memberProgram"
      >
        <ng-option
          *ngFor="let program of programs"
          [value]="program._id"
        >
          {{program.name}}
        </ng-option>
      </ng-select>
    </akl-select-wrapper>

    <akl-select-wrapper label="Employer">
      <ng-select
        formControlName="employer"
      >
        <ng-option
          *ngFor="let employer of employers"
          [value]="employer._id"
        >
          {{employer.name}}
        </ng-option>
      </ng-select>
    </akl-select-wrapper>

    <div class="controls">
      <akl-link
        *ngIf="user"
        (linkClick)="onRejectClick()"
      >
        <span class="icon icon-delete"></span>
        {{rejectTitle}}
      </akl-link>

      <akl-button
        *ngIf="!readOnly"
        class="submit"
        (handleClick)="onSubmitClick()"
        [disabled]="form.invalid"
      >
        {{submitTitle}}
      </akl-button>
    </div>
  </form>
</div>
