import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PaginationParams,
  PaginationResponse,
} from '@shared/interfaces/pagination';
import {
  Environment,
  ENVIRONMENT,
} from '../../../environments/environment-token';
import { stringifyValues } from '@shared/helpers/common.helper';
import { Employer } from '../entities/employer';

export type CreateEmployerDto = Omit<Employer, '_id'>;

@Injectable({
  providedIn: 'root',
})
export class EmployersService {
  private baseUrl = `${this.environment.apiUrl}/employers`;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private httpClient: HttpClient,
  ) {}

  create(employer: CreateEmployerDto) {
    return this.httpClient.post<Employer>(this.baseUrl, employer);
  }

  findAll(
    params: PaginationParams = {},
  ): Observable<PaginationResponse<Employer>> {
    const options = {
      params: stringifyValues(params),
    };

    return this.httpClient.get<PaginationResponse<Employer>>(
      this.baseUrl,
      options,
    );
  }
}
