<div class="tabs-container">
  <ul class="tabs">
    <li
      *ngFor="let item of tabs"
      class="tab"
      [routerLink]="item.path"
      routerLinkActive="active"
      [queryParamsHandling]="queryParamsHandling"
      replaceUrl
    >
      <ng-container *ngIf="customTemplate; else defaultTemplate">
        <ng-container
          *ngTemplateOutlet="customTemplate; context: { tab: item }"
        ></ng-container>
      </ng-container>

      <ng-template #defaultTemplate>
        {{item.title}}
      </ng-template>
    </li>
  </ul>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
