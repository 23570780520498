<akl-modal
  [title]="title + '?'"
  (handleClose)="close()"
>
  <div class="options">
    <akl-button
      type="submit"
      (click)="confirm()"
    >Yes</akl-button>
    <akl-link
      (linkClick)="close()"
    >Cancel</akl-link>
  </div>
</akl-modal>
