import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Settings, User } from '@core/entities/user';
import {
  Environment,
  ENVIRONMENT,
} from '../../../environments/environment-token';
import { ApplyingFor } from '@shared/enums/applying-for.enum';
import { ProgramType } from '@core/entities/program';
import { Dayjs } from 'dayjs';
import { Side } from '@core/entities/exercise';
import { QuestionnaireType } from '@core/services/questionnaire.service';

export interface AuthResponse {
  token: string;
  refreshToken: string;
  user: User;
}

export interface Answer {
  questionId: string;
  value: string | number;
  dynamicQuestion?: Answer;
}

export interface UserQuestionnaire {
  questionnaire: string;
  type: QuestionnaireType;
  answers: Answer[];
}

export interface RegisterDto {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dateOfBirth: Dayjs;
  applyingFor: ApplyingFor;
  type: ProgramType;
  address: string;
  secondAddress: string;
  state: string;
  city: string;
  zip: string;
  side: Side;
  questionnaire: UserQuestionnaire;
  settings: Settings;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = `${this.environment.apiUrl}/auth`;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private httpClient: HttpClient,
  ) {
  }

  login(username: string, password: string): Observable<AuthResponse> {
    const url = `${this.baseUrl}/login`;

    return this.httpClient.post<AuthResponse>(url, {
      username,
      password,
    });
  }

  register(employer: string, registerDto: RegisterDto) {
    const url = `${this.baseUrl}/${employer}/register`;

    return this.httpClient.post(url, registerDto);
  }

  logout(): Observable<void> {
    const url = `${this.baseUrl}/logout`;

    return this.httpClient.get<void>(url);
  }

  changePassword(oldPassword: string, newPassword: string) {
    const url = `${this.baseUrl}/password/change`;

    return this.httpClient.post(url, {
      oldPassword,
      newPassword,
    });
  }

  editProfile(firstName: string, lastName: string): Observable<User> {
    const url = `${this.environment.apiUrl}/users/profile`;

    return this.httpClient.patch<User>(url, {
      firstName,
      lastName,
    });
  }

  refresh(refreshToken: string): Observable<AuthResponse> {
    const url = `${this.baseUrl}/refresh`;

    return this.httpClient.post<AuthResponse>(url, { refreshToken });
  }
}
