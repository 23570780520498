import { InjectionToken } from '@angular/core';
import { BodyPart } from '@core/entities/exercise';
import { ProgramType } from '@core/entities/program';

const bodyPartOptions = [
  {
    title: 'Quadriceps',
    value: BodyPart.QUADRICEPS,
  },
  {
    title: 'Glutes',
    value: BodyPart.GLUTES,
  },
  {
    title: 'Hamstrings',
    value: BodyPart.HAMSTRINGS,
  },
  {
    title: 'Adductor',
    value: BodyPart.ADDUCTOR,
  },
  {
    title: 'Paraspinals',
    value: BodyPart.PARASPINALS,
  },
  {
    title: 'Hip Flexor',
    value: BodyPart.HIP_FLEXOR,
  },
  {
    title: 'QL',
    value: BodyPart.QL,
  },
];

const programTypeOptions = [
  {
    title: 'Knee',
    value: ProgramType.KNEE,
  },
  {
    title: 'Hip',
    value: ProgramType.HIP,
  },
  {
    title: 'Low Back',
    value: ProgramType.LOW_BACK,
  },
];

export const constants = {
  dateFormat: 'MM/DD/YYYY',
  defaultPaginationLimit: 10,
  defaultPaginationLimits: [10, 20, 30],
  mobileDeviceWidth: 820,
  bodyPartOptions,
  programTypeOptions,
};

export type Constants = typeof constants;
export const CONSTANTS = new InjectionToken<Constants>('constants');
