import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { QueryParamsHandling } from '@angular/router';

export interface Tab {
  title: string;
  path: string;
  data?: Record<string, any>;
}

@Component({
  selector: 'akl-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit {
  @Input() tabs: Tab[];
  @Input() customTemplate?: TemplateRef<any>;
  @Input() queryParamsHandling: QueryParamsHandling = 'merge';

  constructor() {}

  ngOnInit(): void {}
}
