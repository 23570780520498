import { Exercise, ExerciseType } from '@core/entities/exercise';

export interface ProgramSessionExercise {
  id?: string;
  exercise: Exercise;
  sets: number;
  reps?: number;
  duration?: number;
  minAngle?: number;
  iterationAngle?: number;
  bonusAngle?: number;
  bioFeedbackAlertLevel?: number;
  iterationAngleIncrement?: number;
  bonusAngleIncrement?: number;
}

export interface ProgramSessionData {
  type: ExerciseType;
  exercises?: ProgramSessionExercise[];
  sets?: ProgramSessionExercise[][];
  levels?: ProgramSessionExercise[][];
}

export enum SessionType {
  MobilityPlusStrength = 'MobilityPlusStrength',
  RecoveryPlusMobility = 'RecoveryPlusMobility',
}

export interface Session {
  id?: string;
  type: SessionType;
  data: ProgramSessionData[];
}

export enum ProgramType {
  KNEE = 'knee',
  ARMS = 'arms',
  LEGS = 'legs',
  SHOULDER = 'shoulder',
  HIP = 'hip',
  SPINE = 'spine',
  LOW_BACK = 'lowBack',
}

export interface Program {
  _id?: string;
  name: string;
  type: ProgramType;
  sessions: Session[];
}
