import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'akl-select-wrapper',
  templateUrl: './select-wrapper.component.html',
  styleUrls: ['./select-wrapper.component.styl'],
})
export class SelectWrapperComponent implements OnInit {
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }

}
