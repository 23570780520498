<div class="messages" *ngIf="messages.length; else empty" #messagesEl>
  <div
    *ngFor="let message of messages"
    class="message"
  >
    <div class="message-wrapper" [class.outgoing]="isMessageOutgoing(message)">
      <div class="message-content">
        <strong>{{message.author}}</strong>

        <div class="message-body">{{ message.body }}</div>
      </div>

      <span class="message-datetime">
        {{ message.state.timestamp.toLocaleString() }}
      </span>
    </div>
  </div>
</div>

<ng-template #empty>
  <p class="empty">There are no messages</p>
</ng-template>
