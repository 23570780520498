import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

interface ConfirmModel {
  title: string;
}

@Component({
  selector: 'akl-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.styl'],
})
export class ConfirmationModalComponent
  extends SimpleModalComponent<ConfirmModel, boolean>
  implements OnInit {
  title: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  confirm() {
    this.result = true;
    this.close();
  }
}
