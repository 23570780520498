import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { from, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, switchMap } from 'rxjs/operators';
import { PaginationResponse } from '@shared/interfaces/pagination';
import { Constants, CONSTANTS } from '@core/constants';
import { Program } from '@core/entities/program';
import { ProgramsService } from '@core/services/programs.service';

@Injectable()
export class ProgramsResolver implements Resolve<PaginationResponse<Program>> {
  constructor(
    @Inject(CONSTANTS) protected constants: Constants,
    protected programsService: ProgramsService,
    protected spinner: NgxSpinnerService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<PaginationResponse<Program>> {
    return from(this.spinner.show()).pipe(
      switchMap(() =>
        this.programsService.findAll({
          limit: 1000,
        }),
      ),
      finalize(() => this.spinner.hide()),
    );
  }
}
