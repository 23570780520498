import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

type RadioButtonGroupColor = 'dark' | 'light';

@Component({
  selector: 'akl-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonGroupComponent implements OnInit {
  @Input() label: string;
  @Input() color: RadioButtonGroupColor = 'dark'; 
  @Output() handleClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
