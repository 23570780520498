import { Inject, Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  Environment,
  ENVIRONMENT,
} from '../../../environments/environment-token';
import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth/auth.state';

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private store: Store,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.store.selectSnapshot<string>(AuthState.token);

    if (!req.url.includes(this.environment.apiUrl) || !token) {
      return next.handle(req);
    }

    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
    return next.handle(authReq);
  }
}
