export class Login {
  static readonly type = '[Auth] Login';
  constructor(public username: string, public password: string) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor(public global = false) {}
}

export class Refresh {
  static readonly type = '[Auth] Refresh';
  constructor(public refreshToken: string) {}
}

export class EditProfile {
  static readonly type = '[Auth] Edit profile';
  constructor(public firstName: string, public lastName: string) {}
}
