import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { LinkComponent } from './components/link/link.component';
import { CardComponent } from './components/card/card.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BackComponent } from './components/back/back.component';
import { SelectComponent } from './components/select/select.component';
import { TableComponent } from './components/table/table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TableCellComponent } from './components/table/table-cell/table-cell.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { SearchComponent } from './components/search/search.component';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { ModalComponent } from './components/modal/modal.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { RadioButtonGroupComponent } from './components/radio-button-group/radio-button-group.component';
import { SliderComponent } from './components/slider/slider.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { QuestionnaireFormComponent } from './components/questionnaire-form/questionnaire-form.component';
import { FilterPipe } from './pipes/filter.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { QuillModule } from 'ngx-quill';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { MessagesListComponent } from '@shared/components/messages-list/messages-list.component';
import { SessionTitlePipe } from '@shared/pipes/session-title.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { SelectWrapperComponent } from './components/select-wrapper/select-wrapper.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { LetDirective } from '@shared/directives/let.directive';

@NgModule({
  declarations: [
    InputComponent,
    ButtonComponent,
    LinkComponent,
    CardComponent,
    AvatarComponent,
    BackComponent,
    SelectComponent,
    TableComponent,
    TableCellComponent,
    PaginationComponent,
    TabsComponent,
    SearchComponent,
    StopPropagationDirective,
    LetDirective,
    ModalComponent,
    CheckboxComponent,
    FileUploadComponent,
    RadioButtonGroupComponent,
    SliderComponent,
    RadioButtonComponent,
    QuestionnaireFormComponent,
    FilterPipe,
    CapitalizePipe,
    SessionTitlePipe,
    UserDetailsComponent,
    MessagesListComponent,
    ConfirmationModalComponent,
    SelectWrapperComponent,
    InfiniteScrollComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    QuillModule,
    DragDropModule,
    NgSelectModule,
    NgxSliderModule,
  ],
  entryComponents: [
    ConfirmationModalComponent,
  ],
  exports: [
    ReactiveFormsModule,
    InputComponent,
    ButtonComponent,
    LinkComponent,
    CardComponent,
    AvatarComponent,
    BackComponent,
    SelectComponent,
    TableComponent,
    TableCellComponent,
    PaginationComponent,
    TabsComponent,
    SearchComponent,
    StopPropagationDirective,
    LetDirective,
    ModalComponent,
    CheckboxComponent,
    NgSelectModule,
    FileUploadComponent,
    RadioButtonGroupComponent,
    NgxSliderModule,
    SliderComponent,
    RadioButtonComponent,
    QuestionnaireFormComponent,
    FilterPipe,
    CapitalizePipe,
    SessionTitlePipe,
    QuillModule,
    DragDropModule,
    UserDetailsComponent,
    MessagesListComponent,
    SelectWrapperComponent,
    InfiniteScrollComponent,
  ],
})
export class SharedModule {
}
