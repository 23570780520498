import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { from } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, switchMap } from 'rxjs/operators';
import { User } from '@core/entities/user';
import { CoachesService } from '@core/services/coaches.service';
import { PaginationResponse } from '@shared/interfaces/pagination';

@Injectable()
export class CoachesResolver implements Resolve<PaginationResponse<User>> {
  constructor(
    private coachesService: CoachesService,
    private spinner: NgxSpinnerService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return from(this.spinner.show()).pipe(
      switchMap(() =>
        this.coachesService.findAll({
          limit: 1000,
        }),
      ),
      finalize(() => this.spinner.hide()),
    );
  }
}
